<template>
  <div
    class="relative flex min-h-screen flex-col overflow-hidden bg-cover bg-fixed text-sm text-grey-blue antialiased"
    style="background-image: url('/images/background_image.jpg')"
  >
    <div class="flex h-screen w-screen justify-center bg-gradient-to-br from-trublue/75 to-[#A363F8]/75 py-10">
      <div class="max-w-xs md:max-w-xl lg:max-w-3xl">
        <slot />
      </div>
    </div>
  </div>
</template>
